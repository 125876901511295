import React from 'react'
import styled from 'styled-components'


export default props => {
  return (
    <header className={props.active && props.active !== 'home'? 'not-top' : '' }>
      <div className="headarea">
        <div className="header_logo"> <img src={require("../../image/drupalcampden2022.svg")}/> </div>
        <nav className="pc">
          <ul>
            <li><a className={props.active && props.active === 'home'? 'pagelink current' : 'pagelink' } rel="home" href="/">HOME</a></li>
            <li><a className={props.active && props.active === 'about'? 'pagelink current' : 'pagelink' } rel="about" href="/about">AboutDEN</a></li>
            <li><a className={props.active && props.active === 'session'? 'pagelink current' : 'pagelink' } rel="session" href="/sessions">セッション</a></li>
            <li><a className={props.active && props.active === 'inclusion'? 'pagelink current' : 'pagelink' } rel="inclusion" href="/diversity-inclusion">多様性とインクルージョン</a></li>
            <li><a className={props.active && props.active === 'reserve'? 'reserve current' : 'reserve' }  href="https://drupal-meetup-den.connpass.com/event/229256/" target="_blank"><span className="material-icons outlined">group</span>参加申し込み</a></li>
          </ul>
        </nav>
        <div className="hamburger-menu lg">
          <input type="checkbox" id="menu-btn-check" />
          <label for="menu-btn-check" className="menu-btn"><span></span></label>
          <div className="menu-content">
            <ul>
              <li> <a rel="home" href="/">HOME</a> </li>
              <li> <a href="/about">AboutDEN</a> </li>
              <li> <a href="/sessions">セッション</a> </li>
              <li> <a href="/diversity-inclusion">多様性とインクルージョン</a> </li>
              <li className="last"> <a className="reserve" href="/diversity-inclusion" target="_blank"><span class="material-icons outlined">group</span>参加申し込み</a></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

const Header = styled.div`
`
