import React from 'react'
import styled from 'styled-components'
import FooterLink from './link'
import { Container, Row, Grid, Inner} from '../responsive/'
import ImgBanner from "../../image/top/banner.png"
import ImgBannerSp from "../../image/top/banner_sp.png"

export default props => 
  <footer>
  <div className="footerblock">
      <p className="footer-img"><a  href="https://drupal-meetup-den.connpass.com/event/229256/" target="_blank"><img src={ ImgBanner } className="pc" /><img src={ ImgBannerSp } className="sp" /></a></p>
    </div>
    <ul>
      <li> <a rel="home" href="/">HOME</a> </li>
      <li> <a href="/about">AboutDEN</a> </li>
      <li> <a href="/sessions">セッション</a> </li>
      <li> <a href="/diversity-inclusion">多様性とインクルージョン</a> </li>
    </ul>
    <p><img src={require("../../image/copyright.svg")} /></p>
  </footer>


const Links = styled.div`
  padding-top:2em;
  padding-bottom:2em;
`
